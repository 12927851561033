export const API_URL = 'https://fructs-backend.ssv-design.com/Main';

export const GET_PRODUCT_NAME_BY_ID = API_URL + '/getProductNameById';
export const GET_PRODUCTS = API_URL + '/getProducts';
export const GET_ORDERS = API_URL + '/getOrders';
export const ADD_PRODUCT = API_URL + '/addProduct';
export const REMOVE_PRODUCT = API_URL + '/removeProduct';
export const EDIT_TABLE_FIELD = API_URL + '/editTableField';
export const SAVE_ORDER = API_URL + '/saveOrder';
export const CREATE_ORDER = API_URL + '/createOrder';
export const GET_COUNT_ALL_ORDERS = API_URL + '/countAllOrders';

export const CHECK_OPEN_ORDER = API_URL + '/checkOpenOrder'
export const DELETE_ORDER = API_URL + '/deleteOrder'
export const DELETE_ITEM_FROM_ORDER = API_URL + '/deleteItemFromOrder'

export const amountFormatter = (number) => Number(number).toFixed(2);

export const ITEMS_ON_THE_PAGE = 10