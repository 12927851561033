import React, { Component } from "react";
import SideBar from "../sidebar";
import Footer from "../footer";
import OrderTable from "./order-table";
import moment from "moment";
import Input from "../../helpers/input";
import Button from "../../helpers/button";
import Select from "../../helpers/select";
import {
  GET_PRODUCTS,
  GET_PRODUCT_NAME_BY_ID,
  SAVE_ORDER,
  CREATE_ORDER,
  ADD_PRODUCT,
  CHECK_OPEN_ORDER,
  DELETE_ORDER,
  DELETE_ITEM_FROM_ORDER
} from "../../helpers/constants";
import axiosFetch from "../../helpers/axios";

import "./add-order.scss";

export default class AddOrder extends Component {
  state = {
    productId: 0,
    count: "",
    orderId: 0,
    showOrderTable: false,
    productsArray: [],
    tablesHeaders: [
      { th: "#" },
      { th: "Наименование товара" },
      { th: "Количество" },
      { th: "Действия" },
    ],
    tableData: [],
    ordersArray: [],
    saveOrderStatus: false,
    productName: "",
    showDetailsModal: false,
  };

  componentDidMount() {
    this.getProducts();
    this.checkLastOpenOrder()
  }

  getProducts = () => {
    axiosFetch(GET_PRODUCTS).then((productsArray) => {
      this.setState({ productsArray });
    });
  };

  checkLastOpenOrder = () => {
    axiosFetch(CHECK_OPEN_ORDER).then((lastOrderData) => {
      if (lastOrderData && lastOrderData[0].orderId) {
        this.setState({
          orderId: lastOrderData[0].orderId,
          tableData: lastOrderData[0].orderItems || [],
          showOrderTable: true
        })
      }
    });
  };

  changeFieldsState = (field, inputValue) => {
    this.setState({ [field]: inputValue });
  };

  addOrder = (productId, count, price) => {
    const { tableData, orderId } = this.state;

    const fd = new FormData();
    fd.append("productId", productId);

    axiosFetch(GET_PRODUCT_NAME_BY_ID, fd).then((productName) => {
      const line = [
        {
          lineId: tableData.length,
          productId: productId,
          productName: productName,
          count: count,
          price: price,
        },
      ];

      const newMoreDownArray = [...tableData, ...line];

      this.setState({
        tableData: newMoreDownArray,
        price: "",
        count: "",
        // productId: 0,
      });
    });



    const fData = new FormData();

    fData.append("productId", productId);
    fData.append("count", count);
    fData.append("maxId", orderId);


    axiosFetch(SAVE_ORDER, fData).then((response) => {
      // if (response) {
      //   this.resetOrder()
      // }
    });
  };

  onDeleteItem = (id) => {
    const { orderId } = this.state

    const formData = new FormData();
    formData.append("orderId", orderId);
    formData.append("productId", id);
    axiosFetch(DELETE_ITEM_FROM_ORDER, formData).then(() => {
      this.checkLastOpenOrder()
    })
  };


  createOrder = () => {
    const formData = new FormData();
    formData.append("data", moment().format("DD.MM.YYYY HH:mm"));
    axiosFetch(CREATE_ORDER, formData).then((orderId) => {
      this.setState({ orderId, showOrderTable: true })
    })
  }

  deleteOrder = () => {
    const { orderId } = this.state

    const formData = new FormData();
    formData.append("orderId", orderId);
    axiosFetch(DELETE_ORDER, formData).then(() => {
      this.setState({
        orderId: 0,
        showOrderTable: false,
        tableData: [],
      })
    })
  }

  recheckItems = () => {

    const {
      tableData,
      productsArray,
    } = this.state;

    const uniqItems = []

    productsArray.forEach(({ id, name }) => {
      let summ = 0
      const test = tableData.filter(tableItem => tableItem.productId === id)
      test.forEach(i => summ = summ + Number(i.count))
      uniqItems.push({ name, summ })
    })

    return uniqItems.filter(i => i.summ !== 0)
  }

  showDetailsModal = () => {
    const tableFilteredData = this.recheckItems()

    return (
      <div className={"details-modal"}>
        <div className={"details-modals-content"}>
          <div
            className={"close-icon"}
            onClick={() => this.setState({ showDetailsModal: false })}
          >
            X
          </div>
          <table className="table table-hover">
            <thead>
              <tr>
                <th>Наименование</th>
                <th>Кол-во (шт.)</th>
              </tr>
            </thead>
            <tbody>
              {this.renderOrderDetails(tableFilteredData)}
            </tbody>
          </table>


        </div>
      </div>
    );
  };

  renderOrderDetails = (data) => {
    const orderDetails = data.map((oneLine, i) => {
      const { name, summ } = oneLine;
      return (
        <tr key={i}>
          <td>{name} </td>
          <td> {summ} кг</td>
        </tr>
      );
    });

    return orderDetails;
  }

  // saveOrder = () => {
  //   const { tableData } = this.state;

  //   const formData = new FormData();
  //   formData.append("data", moment().format("DD.MM.YYYY HH:mm"));

  //   axiosFetch(CREATE_ORDER, formData).then((maxId) => {

  //     tableData.forEach((oneLine) => {
  //       const { productName, price, productId, count } = oneLine;

  //       const fd = new FormData();
  //       fd.append("productName", productName);
  //       fd.append("price", price);
  //       fd.append("productId", productId);
  //       fd.append("count", count);
  //       fd.append("maxId", maxId);


  //       axiosFetch(SAVE_ORDER, fd).then((response) => {
  //         if (response) {
  //           this.resetOrder()
  //         }
  //       });
  //     });
  //   });
  // };

  resetOrder = () => {
    this.setState({
      tableData: [],
      count: "",
      productId: 0,
    });
  }

  addProduct = (productName) => {
    const fd = new FormData();
    fd.append("productName", productName);

    axiosFetch(ADD_PRODUCT, fd).then(() => {
      this.setState({ productName: "" })
      this.getProducts()
    });
  }

  render() {
    const { pageTitle } = this.props;
    const {
      productId,
      count,
      tablesHeaders,
      tableData,
      productsArray,
      showOrderTable,
      orderId,
      showDetailsModal,
      productName
    } = this.state;


    return (
      <div className="wrapper ">
        {showDetailsModal && this.showDetailsModal()}
        <SideBar activeMenuItem={pageTitle} />
        <div className="main-panel">
          <div className="content">
            <div className="container-fluid">

              {showOrderTable && <div className="col-md-12 date-container">
                <div className="card card-stats row">
                  <div className="col-md-4">
                    <label htmlFor="productName">Наименование</label>
                    <Input className={"form-control"}
                      type={"text"}
                      id={"productName"}
                      value={productName}
                      onChange={(e) => this.changeFieldsState("productName", e.target.value)}
                    />
                  </div>
                  <Button type={'button'}
                    text={'Добавить товар в библиотеку'}
                    className={'btn btn-success '}
                    disabled={!productName}
                    onClick={() => this.addProduct(productName)} />
                </div>
              </div>}


              {!showOrderTable && <div className="col-md-12 date-container">
                <div className="card card-stats row">

                  <Button type={'button'}
                    text={'Создать заявку'}
                    className={'btn btn-success '}
                    // disabled={!productName}
                    onClick={() => this.createOrder()} />
                </div>
              </div>}

              {showOrderTable && <div className="col-md-12 date-container">
                <div className="order-title">
                  Заказ № {orderId} <b style={{ cursor: "pointer" }} onClick={() => this.deleteOrder()}>(удалить заказ)</b>
                </div>
                <div className="card card-stats row">
                  <div className="col-md-6">

                    <Select
                      className={"form-control"}
                      items={productsArray}
                      defaultValue={"Выберите товар"}
                      selectemItem={productId}
                      onChange={(e) =>
                        this.changeFieldsState("productId", e.target.value)
                      }
                    />
                  </div>
                  <div className="col-md-6">

                    <Input
                      className={"form-control"}
                      type={"number"}
                      id={"count"}
                      value={count}
                      placeholder={"Количество товара (кг)"}
                      onChange={(e) =>
                        this.changeFieldsState("count", e.target.value)
                      }
                    />
                  </div>
                  <Button
                    type={"button"}
                    text={"Добавить"}
                    disabled={!count}
                    className={"btn btn-success waves-effect waves-light m-l-5"}
                    onClick={() => this.addOrder(productId, count)}
                  />
                </div>
              </div>}
              {tableData.length > 0 && (
                <div className="content">
                  <div className="container-fluid">
                    <div className="col-md-12 date-container print-button-container">
                      <div onClick={() => this.setState({ showDetailsModal: true })} className="print-content">

                        <Button
                          type={"button"}
                          text={"Подсчитать"}
                          className={
                            "btn btn-success waves-effect waves-light m-l-5"
                          }
                        />

                      </div>

                      <OrderTable
                        tablesHeaders={tablesHeaders}
                        onDeleteItem={this.onDeleteItem}
                        tableData={tableData}
                        resetOrder={this.resetOrder}
                        ref={(el) => (this.componentRef = el)}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}
